export function useDrawers() {
  return useState('drawers', () => ({
    cart: {
      open: false,
    },
    navigation: {
      open: false,
    },
  }))
}
